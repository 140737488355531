//IMPORTACIONES
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../Login";
import Formulario from "../Formulario";
import RutaPrivada from "./RutaPrivada";
import PublicRoute from "./PublicRoute";

function Enrutador() {
  return (
    //update this to react-router-domv6
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />

        <Route
          path="/home"
          element={
            <RutaPrivada>
              <Formulario />
            </RutaPrivada>
          }
        />

        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default Enrutador;
