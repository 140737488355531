export const InvalidToken = (error, navigate) => {
  //DESESTRUCTURANDO LA DATA DEL ERROR
  console.log(error.response);
  if (error.response !== undefined) {
    if (error.response.status === 401) {
      //ELIMINANDO EL NOMBRE DE USUARIO DEL SSESION STORAGE
      sessionStorage.removeItem("user");
      // //ELIMINANDO EL TOKEN DE ACCESO DE SSESION STORAGE
      sessionStorage.removeItem("token");
      // //ENVIANDO AL USUARIO A LA PAGINA DE LOGIN
      navigate("/");
    }
  }
};
