//IMPORTACIONES
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import "../App.css";
import { toast } from "react-toastify";
import ImgRoute from "../helpers/ImgRoute";
import ip from "../Ip/IpPeticiones";
import { useNavigate } from "react-router-dom";
import { InvalidToken } from "../helpers/InvalidToken";

export default function GraficaCantidadesServicios({ Averia }) {
  const navigate = useNavigate();

  //ESTADOS
  //PARA LOS DATOS DE LAS CANTIDADES DE CLIENTE
  const [datosClientes, setDatosClientes] = useState({});
  //PARA MOSTRAR O NO EL ICONO DE CARGA
  const [carga, setCarga] = useState(false);

  //CICLO DE VIDA DE ACTUALIZACION PARA LA PROPIEDAD AVERIA DATO
  useEffect(async () => {
    //EN DADO CASO DE QUE NO SE HAYA COLOCADO UN VALOR VALIDO EN LOS INPUT DE AP Y SITE
    if (
      Averia.dato == null ||
      Averia.dato == undefined ||
      Averia.dato.length == 0
    )
      return console.log("no renderizar");

    try {
      //MOSTRANDO EL ICONO DE CARGA
      setCarga(true);
      //HACIENDO LA PETICION PARA OBTENER LOS DATOS
      const datos = await axios.post(
        `${ip}/Boletos/cantidadCircuitos`,
        { tipoAveria: Averia.tipoAveria, dato: Averia.dato },
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );
      //AGREGANDO LOS DATOS DE LA GRAFICA
      setDatosClientes(datos.data);
      //REMOVIENDO EL ICONO DE CARGA DEL DOM
      setCarga(false);
    } catch (error) {
      //ENVIANDO UNA NOTIFICACION DE ERROR EN DADO CASO HAYA UN PROBLEMA CON LA PETICION
      toast.warning(
        "Ocurrio un problema al pedir las cantidades" + error.message
      );
      InvalidToken(error, navigate);
    }
  }, [Averia.dato]);

  //DATOS DE MANIPULACION DE LA GRAFICA
  const data = {
    labels: ["Business VIP", "Wholesale", "Business", "Enterprise"],
    datasets: [
      {
        label: "Cantidades De Circuitos",
        data: [
          datosClientes["Business VIP"],
          datosClientes.Wholesale,
          datosClientes.Business,
          datosClientes.Enterprise,
        ],
        fill: false,
        backgroundColor: ["#75cede", "#3d3c41", "#009fdb", "#323070"],
        borderColor: "rgba(22, 99, 132, 0.6)",
      },
    ],
  };
  //OPCIONES DE LA GRAFICA
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  //CONFIGURANDO LAS NOTIFICACIONES
  toast.configure();

  return (
    <div className="">
      {/*ICONO DE CARGA*/}
      {carga ? (
        <img className="animacionCarga mt-1" src={ImgRoute.CargarSimbol} />
      ) : (
        ""
      )}

      {/*EN DADO CASO NO HAYA NINGUN TIPO DE CLIENTE POR EL SITE 'TORRE' O AP*/}
      {datosClientes["Business VIP"] == 0 &&
      datosClientes.Wholesale == 0 &&
      datosClientes.Business == 0 ? (
        <h4 className="animacionPanel text-danger">
          No hay circuitos que salgan por dicho site o ap
        </h4>
      ) : (
        ""
      )}

      {/*COMPONENTE GRAFICA DE BARRAS */}
      <Bar options={options} data={data} height="85" />
    </div>
  );
}
