import React from 'react'

export default function Paneles({element,show,comparation}) {




  return(<div>

{
    
    comparation===show ? <div className="tab-pane fade show active"  role="tabpanel" aria-labelledby="home-tab">{element}</div> : ""


}

  
  
</div>




) 




}
