//IMPORTACIONES
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import ImgRoute from "../helpers/ImgRoute";
import ip from "../Ip/IpPeticiones";
import { InvalidToken } from "../helpers/InvalidToken";
import { useNavigate } from "react-router-dom";

export default function FormularioClientesResidenciales(props) {
  const navigate = useNavigate();

  //ESTADOS
  //PARA EL NIVEL DE AVERIA SI ES SITE O AP
  const [nivelAveriaR, setnivelAveriaR] = useState("Ap");
  //PARA LA TORRE ELEGIDA 'SITE'
  const [sitesElegido, setsitesElegido] = useState("");
  //PARA MOSTRAR O NO EL ICONO DE CARGA
  const [carga, setCarga] = useState(false);

  //REFERENCIAS
  //PARA EL DIV QUE CONTIENE EL SELECT DE LOS SITES
  const refCajaSelectSites = useRef();
  //PARA EL FORMULARIO 'INPUT' DE TEXTO DEL AP
  const refTextForm = useRef();
  //PARA EL FORMULARIO 'INPUT' DEL RADIO BUTTON
  const apRadioR = useRef();

  //CICLO DE VIDA DE ACTUALIZACION DEL ESTADO nivelAveriaR
  useEffect(() => {
    //EN DADO CASO QUE EL DE QUE EL VALOR DEL ESTADO nivelAveriaR SEA AP
    if (nivelAveriaR == "Ap") {
      //CAMBIANDO LA CLASE DEL INPUT' DE TEXTO DEL AP
      refTextForm.current.className = "form-control";
      //AGREGANDOLE EL ATRIBUTO DE REQUERIDO INPUT' DE TEXTO DEL AP
      refTextForm.current.setAttribute("required", "true");
      //AGREGANDOLE LA CLASE D-NONE AL DIV QUE CONTIENE EL SELECT DE LOS SITES
      refCajaSelectSites.current.className = "d-none";
    }
    //EN DADO CASO QUE EL DE QUE EL VALOR DEL ESTADO nivelAveriaR SEA SITE
    if (nivelAveriaR == "Site") {
      //CAMBIANDO LA CLASE DEL DIV QUE CONTIENE EL SELECT DE LOS SITES PARA QUE SE VEA EN EL DOM
      refCajaSelectSites.current.className = "";
      //CAMBIANDO LA CLASE DEL INPUT TEXT DE AP PARA QUE SE VEA EN EL DOM
      refTextForm.current.className = "form-control d-none";
      //AGREGANDOLE EL ATRIBURO DE REQUERIDO AL INPUT TEXT DEL AP
      refTextForm.current.removeAttribute("required");
    }
  }, [nivelAveriaR]);

  return (
    <Formik
      initialValues={{
        apAveriado: "",
      }}
      //FUNCION QUE SE EJECUTA AL MANDAR LOS VALORES DEL FORMULARIO
      onSubmit={async (values) => {
        try {
          //CAMBIANDO EL ESTADO DEL ICONO DE CARGA PARA MOSTRARLO EN EL DOM
          setCarga(true);
          //HACIENDO LA PETICION
          const insertDatabase = await axios.post(
            `${ip}/ResidentialManage/insertResidential`,
            {
              siteOrAp: nivelAveriaR,
              nodo: nivelAveriaR == "Ap" ? values.apAveriado : sitesElegido,
            },
            {
              headers: {
                token: sessionStorage.getItem("token"),
              },
            }
          );
          //EN DADO CASO DE QUE NO HAYA CLIENTES RESIDENCIALES
          if (insertDatabase.status !== 200) {
            //CAMBIO EL ESTADO DEL ICONO DE CARGA PARA QUE NO SE MUESTRE EN EL DOM
            setCarga(false);
            //RETORNO UNA NOTIFICACION DE QUE NO HAY CLIENTES RESIDENCIALES POR ESE SITE O AP
            return toast.warning(
              `No hay clientes Residenciales por el nodo ${
                nivelAveriaR == "Ap" ? values.apAveriado : sitesElegido
              }`
            );
          }

          //EN DADO CASO DE QUE HAYA CLIENTES MUESTRO UNA NOTIFICACION QUE SE INTRODUCIERON EXITOSAMENTE
          toast.success(insertDatabase.data);
          //CAMBIANDO EL ESTADO DEL ICONO DE CARGA PARA NO VERLO EN EL DOM
          setCarga(false);
        } catch (error) {
          //PARAR EL ICONO DE CARGA
          setCarga(false);
          //IMPRIMIENDO EN CONSOLA
          console.log(error.response.data);
          //MOSTRANDO EL ERROR
          toast.error(error.response.data);
          //`Ya hay un registro de averia por el nodo ${req.body.nodo}`
          InvalidToken(error, navigate);
        }
      }}
    >
      {({ handleChange }) => (
        <div>
          <Form>
            {/*CAJA PARA TIPO DE AVERIA SITE O AP */}
            <div className="mb-3">
              <label htmlFor="exampleInput" className="form-label tns">
                Nivel de Avería:
              </label>
              {/*CHECK NIVEL DE AVERIA SITE */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="Site"
                  name="nivelAveria"
                  onChange={(e) => setnivelAveriaR(e.target.value)}
                  checked={nivelAveriaR == "Site"}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Site
                </label>
              </div>
              {/*CHECK NIVEL DE AVERIA AP*/}
              <div className="form-check">
                <input
                  className="form-check-input"
                  ref={apRadioR}
                  type="radio"
                  value="Ap"
                  name="nivelAveria"
                  onChange={(e) => setnivelAveriaR(e.target.value)}
                  checked={nivelAveriaR == "Ap"}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Ap
                </label>
              </div>
            </div>
            {/*CAJA PARA COLOCAR EL SITE O AP AFECTADO*/}
            <div className="form mb-2">
              {/*CAJA PARA EL SELECT SITES BUSQUEDA*/}
              <div ref={refCajaSelectSites} className="">
                <Select
                  options={props.sitesObjetos}
                  onChange={(e) => setsitesElegido(e.value)}
                />
              </div>
              {/* AP AVERIADO */}
              <input
                ref={refTextForm}
                name="apAveriado"
                className="d-none"
                onChange={handleChange}
                placeholder="Punto De Acceso"
                required
              />
            </div>
            {/*SIMBOLO DE CARGA */}
            <div>
              {carga ? (
                <img
                  className="animacionCarga mb-2"
                  src={ImgRoute.CargarSimbol}
                />
              ) : (
                ""
              )}
            </div>
            {/*BOTON PARA CREAR EL DESVIO*/}
            <button type="submit" className="btn btn-primary">
              Crear Desvio
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
}
