//IMPORTACIONES DE PAQUETES
import { React, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Alert,
} from "react-bootstrap";
import axios from "axios";
//IMPORTACIONES PROPIAS
import "./App.css";
import { useState, useRef } from "react";
import UseAuth from "./Rutas/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ip from "./Ip/IpPeticiones";
import { useData } from "./hooks/useData";

export default function Login() {
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  //USANDO EL ESTADO GLOBAL
  const Autentica = UseAuth();
  const [Usuario, setUsuario] = useState("");
  const [Password, setPassword] = useState("");
  const { dataVerifa, handleConsult } = useData(ip, toast, Usuario, Password);

  //PARA ENVIAR LOS DATOS
  async function handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      toast.info("enviado");

      event.preventDefault();

      //consult login
      handleConsult();
    }
  }

  toast.configure();

  useEffect(() => {
    //authenticate the data
    if (dataVerifa.Pase) {
      Autentica.login(dataVerifa.user, dataVerifa.keys);
      navigate("/home");
    }
  }, [dataVerifa]);

  return (
    <Row className="align-items-center justify-content-center tamaño-Fila-Login p-3 animacionLogin">
      <Col className="border rounded text-center p-5 barra" lg={4}>
        <h1 className="mb-4 fw-bold text-white">Osnet Wireless Alert</h1>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="justify-content-center">
            <Col xl={6}>
              <FormGroup>
                <FormControl
                  required
                  placeholder="User"
                  name="Usuario"
                  value={Usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                  type="text"
                />
                <br />

                <FormControl
                  required
                  placeholder="Password"
                  name="Password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="Password"
                />
                <br />
                <Button type="submit" variant="primary">
                  Entrar
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Col>

      <ToastContainer transition={Flip} />
    </Row>
  );
}
