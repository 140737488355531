import React, { useRef } from "react";
import ip from "../Ip/IpPeticiones";
import axios from "axios";
import { CatchErr } from "../helpers/CatchErr";
import { toast } from "react-toastify";
import { InvalidToken } from "../helpers/InvalidToken";
import { useNavigate } from "react-router-dom";

export default function DeleteLotes({ Lotes, getLotes, setLotes }) {
  const navigate = useNavigate();

  //PARA CERRAR LOS BOLETOS
  const cerrarBoletos = async (e) => {
    try {
      const Cierra = await axios.delete(`${ip}/Boletos/ClosedTickets`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          idLote: e.target.id,
        },
      });
      await getLotes("LOTE");

      toast.success(Cierra.data);
    } catch (error) {
      //  let errorValor= error.response.data.Problems[0]!==undefined ? error.response.data.Problems[0] : error.response.data.Problems;
      // toast.warning(errorValor);
      InvalidToken(error, navigate);
      CatchErr().ErrorLotesNoExistente(error, toast, setLotes);
    }
  };
  //PARA QUITAR LOS DESVIOS
  const eliminarDesvio = async (e) => {
    try {
      //HACIENDO LA PETICION
      await axios.delete(`${ip}/ResidentialManage/deleteResidential`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          nodo: e.target.name,
        },
      });
      //OBTENIENDO LOS LOTES ACTUALIZADOS
      await getLotes("DESVIOS");
      //IMPRIMIENDO EL MENSAJE
      toast.success(`Desvio Del Nodo ${e.target.name} Eliminado!`);
    } catch (error) {
      InvalidToken(error, navigate);
      let errorValor =
        error.response.data.Problems[0] !== undefined
          ? error.response.data.Problems[0]
          : error.response.data.Problems;
      toast.warning(errorValor);
    }
  };
  return (
    <>
      <div className="mb-3 lotesTamano animacionPanel">
        <p className="h2 tnt mt-4">Cerrar Tickets Averia Multiple</p>

        <table className="table table-striped">
          <thead>
            <tr>
              <th className="ttab" scope="col">
                Tipo de Avería
              </th>
              <th className="ttab" scope="col">
                Cant. Tickets
              </th>
              <th className="ttab" scope="col">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {/*CONTENIEDO TABLA ELIMINAR DESVIO Y CERRAR BOLETOS */}
            {Lotes.map((element, indice) => {
              if (element.mensajeAveria) {
                return (
                  <tr key={element.ID}>
                    <td>{element.mensajeAveria}</td>
                    <td>{element.Boletos.length}</td>
                    <td key={element.ID}>
                      <button
                        type="button"
                        className="btn btn-danger btnclose"
                        id={element.ID}
                        onClick={cerrarBoletos}
                      >
                        Cerrar
                      </button>
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr>
                    <td>{element.Nodo}</td>
                    <td>{element.CantidadAfectados}</td>
                    <td key={indice}>
                      <button
                        type="button"
                        name={element.Nodo}
                        className="btn btn-warning btnclose"
                        onClick={eliminarDesvio}
                      >
                        Eliminar Desvio
                      </button>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>

        {Lotes.length === 0 ? (
          <h2 className="text-center fw-bold text-danger">
            No Hay Tickets Creados
          </h2>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
