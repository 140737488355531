import "./App.css";
import React from "react";

import Enrutador from "./Rutas/Enrutador";
import AuthProvider from "./Rutas/auth/AuthProvider";
import { toast, ToastContainer, Flip } from "react-toastify";
import Formulario from "./Formulario";

function App() {
  return (
    <>
      <AuthProvider>
        <Enrutador />
      </AuthProvider>
    </>
  );
}

export default App;
