import { usePeticiones } from "../hooks/usePeticiones";

export const CatchErr = () => {
  const ErrorLotesNoExistente = (error, toast, setLotes) => {
    const { handleGetLotes } = usePeticiones();
    const { status } = error.response;

    //in production change to status 500 to test and in dev use status in 400
    if (status === 400) {
      console.log(error.response);
      let errorValor =
        error.response.data.Problems[0] !== undefined
          ? error.response.data.Problems[0]
          : error.response.data.Problems;
      toast.warning(errorValor);
      handleGetLotes(setLotes);
    }
  };

  return { ErrorLotesNoExistente };
};
