//IMPORTACIONES DE PAQUETES
import { React, useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import ip from "../Ip/IpPeticiones";
import { useNavigate } from "react-router-dom";
import { InvalidToken } from "../helpers/InvalidToken";

export default function UnicoAfectado() {
  const navigate = useNavigate();

  //ESTADOS
  //PARA LOS CIRCUITOS DEL CLIENTE
  const [circuitosClienteUnico, setcircuitosClienteUnico] = useState([]);
  //PARA EL ID DEL CLIENTE
  const [idClienteUnico, setidClienteUnico] = useState("");

  //REFERENCIAS
  //PARA EL CAPCHAT DE GOOGLE
  const verificacionreCaptchaClienteUnico = useRef();

  //CICLO DE VIDA DE ACTUALIZACION
  useEffect(() => {
    //ACTUALIZANDO EL ESTADO PARA VACIAR LA LISTA DE CIRCUITOS
    setcircuitosClienteUnico([]);
  }, [idClienteUnico]);

  //OBTENER LOS SERVICIOS DEL CLIENTE
  const getServicesClientUnico = async (e) => {
    //EN DADO CASO DE QUE SE QUIERA CONSULTAR LOS CIRCUITOS SIN CLIENT ID
    if (!idClienteUnico.length > 0)
      return toast.warning(
        "Es necesario colocar el client Id para buscar un circuito valido!"
      );

    try {
      //ENVIANDO UNA PETICION PARA OBTENER LOS CIRCUITOS
      let circuitos = await axios.get(
        `${ip}/Boletos/Servicios/${idClienteUnico}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );
      //EN DADO CASO QUE NO EXISTA UN CLIENTE CON DICHO ID
      if (
        circuitos.data ===
        "No Existe un cliente con ese Id o el cliente no tiene servicios activos"
      ) {
        //ENVIANDO UNA NOTIFICANCION INFORMANDOLO
        toast.warning(circuitos.data);
        //HACIENDO UN RETURN PARA NO SEGUIR LA FUNCION
        return 0;
      }
      //SI TODO SALE BIEN
      setcircuitosClienteUnico(circuitos.data);
    } catch (error) {
      //NOTIFICANDO CUANDO OCURRE UN PROBLEMA
      toast.warning(error.response.data.Problems);
      InvalidToken(error, navigate);
    }
  };

  return (
    <Formik
      initialValues={{
        tipoAveriaClienteUnico: "",
        textoEmailClienteUnico: "",
        circuitoElegido: 0,
      }}
      onSubmit={async (values) => {
        //SI SE MARCA EL CAPCHAP DE GOOGLE
        if (verificacionreCaptchaClienteUnico.current.getValue()) {
          try {
            //DESESTRUCTURANDO LOS VALORES DEL VALUE
            const {
              tipoAveriaClienteUnico,
              textoEmailClienteUnico,
              circuitoElegido,
            } = values;
            //ENVIANDO UNA NOTIFICACION DE QUE SE ENVIARON LOS DATOS
            toast.info("enviado");
            //SI SE COLOCA UN CIRCUITO INVALIDO
            if (!circuitosClienteUnico[circuitoElegido])
              return toast.warning("El circuito colocado es invalido");
            //RESCATANDO EL ID DEL CIRCUITO
            let idCircuito = circuitosClienteUnico[circuitoElegido].split("_");
            //ENVIANDO LA PETICION PARA CREAR EL BOLETO
            let envia = await axios.post(
              `${ip}/Boletos/CreateTicketInvididual`,
              {
                clientID: idClienteUnico,
                TipoAveria: tipoAveriaClienteUnico,
                message: textoEmailClienteUnico,
                idCircuito: idCircuito[0],
                nombreCircuito: idCircuito[1],
                Usuario: sessionStorage.getItem("user"),
              },
              {
                headers: {
                  token: sessionStorage.getItem("token"),
                },
              }
            );
            //SI TODO SALE BIEN
            if (envia.status == 203) toast.success(envia.data);
            //DESMARCADO EL CAPCHAP DE GOOGLE
            verificacionreCaptchaClienteUnico.current.reset();
          } catch (error) {
            //ENVIANDO UNA NOTIFICACION
            error.response.data["Problems"]
              ? toast.warning(error.response.data.Problems[0])
              : toast.warning(error.response.data);
            //DESMARCADO EL CAPCHAP DE GOOGLE
            verificacionreCaptchaClienteUnico.current.reset();
            InvalidToken(error, navigate);
          }
        }
        //EN DADO CASO NO SE MARQUE EL CAPCHAP DE GOOGLE
        else toast.warning("Por favor marque el reCAPTCHA");
      }}
      validate={() => {}}
    >
      {({ values, handleChange }) => (
        <Form className="animacionPanel">
          <p className="h2 tnt mt-4">Reportar Avería</p>
          {/*CAJA ID CLIENTES UNICOS */}
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label tns">
              ID Cliente:
            </label>
            <input
              className="form-control"
              type="number"
              name="idClienteUnico"
              value={idClienteUnico}
              onChange={(e) => setidClienteUnico(e.target.value)}
              required
            />
          </div>
          {/*CAJA CIRCUITOS CLIENTE */}
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label tns">
              Circuito
            </label>

            <select
              className="form-select"
              aria-label="Default select example"
              value={values.circuitoElegido}
              name="circuitoElegido"
              onClick={getServicesClientUnico}
              onChange={handleChange}
            >
              <option defaultValue>Open this select menu</option>
              {circuitosClienteUnico.map((element, index) => (
                <option value={index}>{element}</option>
              ))}
            </select>
          </div>
          {/*CAJA SUBJECT TICKET */}
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label tns">
              Subject Ticket:
            </label>
            <Field
              className="form-control"
              type="text"
              name="tipoAveriaClienteUnico"
              required
            />
          </div>
          {/*CAJA TEXT BOLETO */}
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label tns">
              Mensaje a Enviar:
            </label>
          </div>

          <div className="form-floating">
            <Field
              as="textarea"
              name="textoEmailClienteUnico"
              className="form-control"
              style={{ height: "180px" }}
              required
            />
            <label htmlFor="floatingTextarea2">
              Este es el texto que enviará por Email:
            </label>
            <div className="form-text">
              Añade el Nombre del Cliente con <b>#cliente</b> y el circuito con{" "}
              <b>#servicio</b>
            </div>
          </div>
          {/*CAJA DEL CAPCHAT */}
          <div className="row mt-4">
            <div className="col-12">
              <ReCAPTCHA
                sitekey="6LdAog0dAAAAAP3L8n8FGLWly9FA_lsnUntE5V6n"
                ref={verificacionreCaptchaClienteUnico}
              />
            </div>{" "}
            <div className="col-3">
              <button type="submit" className="btn btn-primary btnosnet">
                Crear Ticket
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
