//IMPORTACIONES DE PAQUETES
import { React, useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import "../App.css";
import GraficaCantidadesServicios from "./GraficaCantidadesServicios.js";
import { Button } from "react-bootstrap";
import ImgRoute from "../helpers/ImgRoute";
import Select from "react-select";
import FormularioClientesResidenciales from "./FormularioClientesResidenciales";
import ip from "../Ip/IpPeticiones";
import { useNavigate } from "react-router-dom";
import { InvalidToken } from "../helpers/InvalidToken";

export default function MultiplesAfectados(props) {
  const navigate = useNavigate();

  //ESTADOS
  //PARA LAS OPCIONES DEL SELECT DE LOS SITES 'Se podria cambiar por una variable normal'
  const [sitesObjeto, setSitesObjeto] = useState([]);
  //PARA ALMACENAR EL SITE ELEGIDO EN EL SELECT
  const [sitesElegido, setsitesElegido] = useState("");
  //PARA EL TIPO DE NIVEL DE AVERIA
  const [nivelAveria, setnivelAveria] = useState("Ap");
  //PARA LA LISTA DE CIRCUITOS
  const [listaCircuitos, setListaCircuitos] = useState([]);
  //PARA EL AP AVERIADO DEL INPUT TEXT
  const [apAveriado, setapAveriado] = useState("");
  //PARA HACER O NO LA PETICION DE LOS CIRCUITOS NUEVAMENTE
  let [Update, setUpdate] = useState(true);
  //PARA ALMACENAR TODOS LOS CIRCUITOS DEVUELTOS POR LA PETICION | NOTA='SE PUEDE CAMBIAR POR UNA VARIABLE NORMAL'
  let [allCircuits, setAllCircuits] = useState([]);
  //PARA ALMACENAR EL PAR DE OPCIONES DE TIPOS DE CLIENTES EN EL SELECT DE TIPO DE CLIENTE  | NOTA='SE PUEDE CAMBIAR POR UNA VARIABLE NORMAL'
  const [OpcionesClientes, setOpcionesClientes] = useState({});
  //PARA ALMACENAR EL TIPO DE CLIENTE ELEGIDO
  const [ClienteElegido, setClienteElegido] = useState();

  //REFERENCIAS
  //PARA LA DIV CAJA QUE CONTIENE EL SELECT DE LOS SITES
  const refCajaSelectSites = useRef();
  //PARA EL INPUT DONDE SE COLOCA EL PUNTO DE ACCESO AP
  const refTextForm = useRef();
  //PARA EL CAPCHAT DE GOOGLE
  const verificacionreCaptchaClientesMultiples = useRef();
  //PARA EL DIV CAJA QUE CONTIENE TODOS LOS CIRCUITOS
  const classCajaCircuitos = useRef();
  //PARA EL BOTON DE CIRCUITOS INVIDIDUALES
  const BotonCircuitosInvididuales = useRef();
  //PARA EL INPUT DE RADIO DE LA OPCION DE AVERIA AP  | NOTA='ESTO SE PUEDE CAMBIAR'
  const apRadio = useRef();
  //PARA LA IMAGEN DEL ICONO DE CARGA
  const Cargador = useRef();
  //PARA LA CAJA DIV QUE CONTIENE EL FORMULARIO DE CLIENTES RESIDENCIALES
  const cajaFormularioClientesResidenciales = useRef();
  //PARA LA CAJA DIV QUE CONTIENE EL FORMULARIO DE CLIENTES IMPORTANDTES
  const cajaFormularioClientesImportantes = useRef();

  //CICLO DE VIDA DE MONTAJE
  useEffect(async () => {
    //CAMBIANDO LA CLASE DE LA CAJA DE CIRCUITOS PARA QUE NO SE VEA
    classCajaCircuitos.current.className = "d-none";
    //HACIENDO UNA PETICION PARA TRAER LAS OPCIONES DE LOS SITES | NOTA='ESTO DEBERIA SER  UN CONST NO LET'
    try {
      let valores = await axios.get(`${ip}/Boletos/SitesOptions`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      //CREANDO LA VARIABLE LA VARIABLE QUE ALMACENARA LAS OPCIONES DE LOS SITES
      let sitesValues = [];
      //RECORRIENDO TODOS LOS SITES
      valores.data.forEach((element, index) => {
        //ALMACENANDO UNO A UNO EN LA VARIBLE CON SU ESTRUCTURA RESPECTIVA LOS DATOS DE LOS SITES | NOTA='ESTO DEBERIA CAMBIARSE POR UN PUSH'
        sitesValues[index] = { value: element, label: element };
      });
      //ACTUALIZANDO EL ESTADO DEL STATE QUE ALMACENA LAS OPCIONES DE LOS SITES
      setSitesObjeto(sitesValues);
      //ACTUALIZANDO EL ESTADO DEL STATE QUE ALMACENA LAS OPCIONES DE LOS CLIENTES
      setOpcionesClientes([
        { value: 1, label: "Importantes" },
        { value: 2, label: "Residenciales" },
      ]);
    } catch (error) {
      InvalidToken(error, navigate);
    }
  }, []);

  //CICLO DE VIDA DE ACTUALIZACION EN DADO CASO CAMBIEN LOS DATOS DE nivelAveria sitesElegido apAveriado
  useEffect(() => {
    //INDICANDO QUE VA A SER NECESARIO HACER UNA PETICION NUEVA DE CIRCUITOS
    setUpdate(true);
    //CAMBIANDO LA CLASE DE LA CAJA DIV QUE CONTIENE LOS CIRCUITOS
    classCajaCircuitos.current.className = "d-none";
    //CAMBIANDO LA CLASE DEL BOTON DE CIRCUITOS INVIDIDUALES PARA QUE NO SE VEA
    BotonCircuitosInvididuales.current.className = "d-none";
    //SI EL NIVEL DE AVERIA ES AP
    if (nivelAveria == "Ap") {
      //CAMBIANDO LA CLASE DEL INPUT DE AP PARA QUE SEA VISIBLE
      refTextForm.current.className = "form-control";
      //Y ASIGNANDOLE EL ATRIBUTO DE REQUERIDO
      refTextForm.current.setAttribute("required", "true");
      //CAMBIANDO LA ACLASE DEL DIV CAJA QUE CONTIENE EL SELECT DE LA CAJA DE CIRCUITOS PARA QUE NO SE VEA
      refCajaSelectSites.current.className = "d-none";
    }
    //SI EL NIVEL DE AVERIA ES SITE
    if (nivelAveria == "Site") {
      //CAMBIANDO LA CLASE DE LA CAJA DIV QUE CONTIENE EL SELECT DE LOS SITES PARA QUE SEA VISIBLE
      refCajaSelectSites.current.className = "";
      //CAMBIANDO LA CLASE DEL INPUT DE LOS APS PARA QUE NO SE VEA
      refTextForm.current.className = "form-control  d-none";
      //QUITANDOLE EL ATRIBUTO DE REQUERIDO AL INPUT DE AP
      refTextForm.current.removeAttribute("required");
    }
  }, [nivelAveria, sitesElegido, apAveriado]);

  //CICLO DE VIDA DE ACTUALIZACION EN DADO CASO QUE CAMBIEN LOS DATOS DE ClienteElegido
  useEffect(() => {
    //SI EL ESTADO ClienteElegido ES RESIDENCIALES
    if (ClienteElegido == "Residenciales") {
      //SE CAMBIA LA CLASE DE LA CAJA DIV QUE CONTIENE EL FORMULARIO DE LOS CLIENTES RESIDENCIALES
      cajaFormularioClientesResidenciales.current.className = "animacionPanel";
      //SE CAMBIA LA CLASE DE LA CAJA DIV QUE CONTIENE EL FORMULARIO DE LOS CLIENTES IMPORTANTE PARA QUE NO SE VEA
      cajaFormularioClientesImportantes.current.className = "d-none";
    }
    //SI EL ESTADO ClienteElegido ES IMPORTANTES
    else if (ClienteElegido == "Importantes") {
      //SE CAMBIA LA CLASE DE LA CAJA DIV QUE CONTIENE EL FORMULARIO DE LOS CLIENTES IMPORTANTES PARA QUE SE VEA
      cajaFormularioClientesImportantes.current.className = "animacionPanel";
      //SE CAMBIA LA CLASE DE LA CAJA DIV QUE CONTIENE EL FORMULARIO DE LOS CLIENTES RESIDENCIALES PARA QUE NO SE VEA
      cajaFormularioClientesResidenciales.current.className = "d-none";
    }
  }, [ClienteElegido]);

  //PARA TRAER LOS CIRCUITOS DE DICHO AP O SITE
  const getCircuitos = async (clientType, equipoAfectado) => {
    //OBJETO QUE CONTIENE LOS TIPOS DE CLIENTE
    const tiposDeClientes = {
      "Business VIP": clientType[0],
      Wholesale: clientType[1],
      Business: clientType[2],
      Enterprise: clientType[3],
    };
    //HACE LA PETICION NUEVAMENTE EN DADO CASO QUE EL TIPO DE AVERIA CAMBIE
    if (Update) {
      try {
        //HACIENDO LA PETICION PARA TRAER LOS CIRCUITOS
        let CircuitosPeticion = await axios.post(
          `${ip}/Boletos/CircuitosArea`,
          {
            //BODY ENVIADO
            NivelAveria: nivelAveria,
            siteOrAp: equipoAfectado,
          },
          {
            headers: {
              token: sessionStorage.getItem("token"),
            },
          }
        );

        //DATOS DE LA PETICION
        let datosPeticion = CircuitosPeticion.data.Data;
        //ACTUALIZANDO EL ESTADO DE TODOS LOS CIRCUITOS
        setAllCircuits(datosPeticion);
        //LOS TIPOS DE CLIENTE PASADOS POR PARAMETRO
        let clientesFiltrados = Object.keys(tiposDeClientes).filter(
          (element) => tiposDeClientes[element] == true
        );
        //LOS CIRCUITOS FILTRADOS
        let CircuitosFiltrados = datosPeticion.filter((element) =>
          clientesFiltrados.includes(element.Tipo)
        );
        //ACTUALIZANDO EL LISTADO
        setListaCircuitos(CircuitosFiltrados);
        //CAMBIANDO LA CLASE DE LOS CIRCUITOS FILTRADOS PARA MOSTRARLO EN EL DOM
        classCajaCircuitos.current.className =
          "animacionPanel pt-3 ps-4 cajaListaCircuitos";
        //CAMBIANDO EL ESTADO DE UPDATE A FALSE
        setUpdate(false);
      } catch (error) {
        //ENVIANDO UNA NOTIFICACION EN DADO CASO OCURRA UN PROBLEMA
        toast.warning(
          "Ocurrio un inconveniente al hacer la peticion de los circuitos " +
            error.message
        );
        InvalidToken(error, navigate);
      }
    }
    //NO HACER LA PETICION NUEVAMENTE SI EL TIPO DE AVERIA NO CAMBIA
    if (!Update) {
      //LOS TIPOS DE CLIENTE PASADOS POR PARAMETRO
      let clientesFiltrados = Object.keys(tiposDeClientes).filter(
        (element) => tiposDeClientes[element] == true
      );
      //LOS CIRCUITOS FILTRADOS
      let CircuitosFiltrados = allCircuits.filter((element) =>
        clientesFiltrados.includes(element.Tipo)
      );
      //ACTUALIZANDO LA LISTA DE CIRCUITOS
      setListaCircuitos(CircuitosFiltrados);
      //CAMBIANDO LA CLASE DE LA CAJA DE LOS CIRCUITOS
      classCajaCircuitos.current.className =
        "animacionPanel pt-3 ps-4 cajaListaCircuitos";
    }
  };

  return (
    <div>
      <p className="h2 tnt mt-3">Reportar Avería</p>

      {/*CAJA PARA EL TIPO DE CLIENTE DE AVERIA SELECT INPUT*/}
      <label htmlFor="exampleInputPassword1" className="form-label tns">
        Clientes
      </label>
      <div className="mb-3 ">
        <Select
          options={OpcionesClientes}
          placeholder="Importantes"
          onChange={(e) => setClienteElegido(e.label)}
        />
      </div>
      {/* PARA CLIENTES IMPORTANTES */}
      <div ref={cajaFormularioClientesImportantes}>
        <Formik
          initialValues={{
            textoEmailMultiples: "",
            ClienteBusinessVIP: false,
            ClienteWholesale: false,
            ClienteBusiness: false,
            ClienteEnterprise: false,
            ticketID: "",
          }}
          //FUNCION QUE ENVIA LA INFO DEL FORMULARIO
          onSubmit={async (values) => {
            //ENVIANDO UNA NOTIFICACION DE QUE SE ENVIARON LOS DATOS
            toast.info("enviado");
            //DESESTRUCTURANDO EL textoEmailMultiples Y  ticketID DE LOS VALUES
            const { textoEmailMultiples, ticketID } = values;
            //DANDOLE UN VALOR A ESTA VARIABLE SEGUN EL NIVEL DE AVERIA
            let SiteorAp = nivelAveria == "Site" ? sitesElegido : apAveriado;
            //VERIFICANDO SI EL CAPCHAT DE GOOGLE ESTA MARCADO
            if (verificacionreCaptchaClientesMultiples.current.getValue()) {
              //PETICION PARA MULTIPLES CLIENTES
              if (BotonCircuitosInvididuales.current.value === "todos") {
                try {
                  //HACIENDO UNA PETICION PARA CREAR LOS TICKETS
                  const Peticion = await axios.post(
                    `${ip}/Boletos/CreateTicketsAveriaMultiple`,
                    {
                      Naveria: nivelAveria,
                      SiteorAp: SiteorAp,
                      clientType: [
                        String(values.ClienteBusinessVIP),
                        String(values.ClienteWholesale),
                        String(values.ClienteBusiness),
                        String(values.ClienteEnterprise),
                      ],
                      textoEmail: textoEmailMultiples,
                      Usuario: sessionStorage.getItem("user"),
                      ticketAVERIA: parseInt(ticketID),
                    },
                    {
                      headers: {
                        token: sessionStorage.getItem("token"),
                      },
                    }
                  );
                  //EN DADO CASO DE QUE NO HAYA CLIENTES DE ESE TIPO
                  if (Peticion.data == 0)
                    toast.warning(
                      "No hay clientes de ese tipo que tengan servicios por dicho site o ap"
                    );
                  //SI TODO SALE BIEN
                  if (Peticion.data)
                    toast.success("Reportes Creados Exitosamente!");
                  //DESMARCANDO EL CAPCHAT DE GOOGLE
                  verificacionreCaptchaClientesMultiples.current.reset();
                } catch (error) {
                  let errorValor =
                    error.response.data.Problems[0] !== undefined
                      ? error.response.data.Problems[0]
                      : error.response.data.Problems;
                  //ENVIANDO UNA NOTIFICACION DE QUE OCURRIO UN PROBLEMA
                  toast.warning(errorValor);
                  //DESMARCANDO EL CAPCHAT DE GOOGLE
                  verificacionreCaptchaClientesMultiples.current.reset();
                  InvalidToken(error, navigate);
                }
              }
              //PETICION PARA INVIDIDUAL
              if (BotonCircuitosInvididuales.current.value === "invididual") {
                try {
                  //OBTENIENDO UN ARREGLO CON LOS CIRCUITOS QUE SE MARCARON
                  const CircuitosMarcados = listaCircuitos.map(
                    (element, index) => {
                      //EN DADO CASO DE QUE EL CHECKED SEA TRUE
                      if (document.getElementById(index).checked)
                        return element;
                    }
                  );
                  //LO QUE SE PASARA AL BACK-END
                  const CircuitosFiltrados = CircuitosMarcados.filter(
                    (element) => element != undefined
                  );
                  //ENVIANDO LA PETICION AL BACK-END
                  let peticion = await axios.post(
                    `${ip}/Boletos/CreateTicketsCircuitosInvididuales`,
                    {
                      nivelAveria,
                      textoEmailMultiples,
                      datosCircuito: CircuitosFiltrados,
                      Usuario: sessionStorage.getItem("user"),
                      SiteorAp,
                      ticketAVERIA: parseInt(ticketID),
                    },
                    {
                      headers: {
                        token: sessionStorage.getItem("token"),
                      },
                    }
                  );
                  //ENVIANDO UNA NOTIFICACION DE QUE SE CREARON LOS TICKETS
                  toast.success(peticion["data"]);
                  //DESMARCANDO EL CAPCHAT DE GOOGLE
                  verificacionreCaptchaClientesMultiples.current.reset();
                } catch (error) {
                  let errorValor =
                    error.response.data.Problems[0] !== undefined
                      ? error.response.data.Problems[0]
                      : error.response.data.Problems;
                  //ENVIANDO LA NOTIFICACION DEL ERROR
                  toast.warning(errorValor);
                  //DESMARCANDO EL CAPCHAT DE GOOGLE
                  verificacionreCaptchaClientesMultiples.current.reset();
                  InvalidToken(error, navigate);
                }
              }
            }
            //EN DADO CASO DE QUE NO SE MARQUE EL CAPCHAT DE GOOGLE
            else {
              toast.warning("Por favor marque el reCAPTCHA");
            }
          }}
          validate={() => {}}
        >
          {({ values, handleChange }) => (
            <Form className=" animacionPanel">
              {/* PARA CLIENTES IMPORTANTES */}
              <div className="">
                {/*CAJA PARA TIPO DE AVERIA SITE O AP */}
                <div className="mb-3">
                  <label htmlFor="exampleInput" className="form-label tns">
                    Nivel de Avería:
                  </label>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Site"
                      name="nivelAveria"
                      onChange={(e) => setnivelAveria(e.target.value)}
                      checked={nivelAveria === "Site"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Site
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Ap"
                      name="nivelAveria"
                      ref={apRadio}
                      onChange={(e) => setnivelAveria(e.target.value)}
                      checked={nivelAveria === "Ap"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Ap
                    </label>
                  </div>
                </div>

                {/*CAJA PARA COLOCAR EL SITE O AP AFECTADO*/}
                <div className="form mb-3">
                  {/*CAJA PARA EL SELECT SITES BUSQUEDA*/}
                  <div ref={refCajaSelectSites} className="d-none">
                    <Select
                      options={sitesObjeto}
                      onChange={(e) => setsitesElegido(e.value)}
                    />
                  </div>

                  <input
                    ref={refTextForm}
                    className="form-control d-none"
                    type="text"
                    placeholder="Punto De Acceso"
                    name="apAveriado"
                    value={apAveriado}
                    onChange={(e) => setapAveriado(e.target.value)}
                    required
                  />
                </div>

                {/*COMPONENTE PARA LA GRAFICA DE BARRAS */}
                <GraficaCantidadesServicios
                  Averia={
                    nivelAveria == "Site"
                      ? { tipoAveria: "Site", dato: sitesElegido }
                      : { tipoAveria: "Ap", dato: apAveriado }
                  }
                />

                {/*CAJA PARA EL TICKET ID*/}
                {/*TITULO LABEL*/}
                <div className="mt-1">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label tns"
                  >
                    Boleto De La Averia:
                  </label>
                </div>

                {/*CAJA QUE CONTIENE EL CAMPO*/}
                <div className="mb-3 mt-1">
                  <Field
                    as=""
                    className="form-control"
                    placeholder="Boleto de Averia"
                    name="ticketID"
                    required
                  />
                </div>

                {/*CAJA PARA LOS CHECK TIPO DE CLIENTE */}
                <div className="mb-1">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label tns"
                  >
                    Tipo de Cliente
                  </label>

                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      name="ClienteBusinessVIP"
                      type="checkbox"
                    />

                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Business VIP
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      name="ClienteWholesale"
                      type="checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Wholesale
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      name="ClienteBusiness"
                      type="checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Business
                    </label>
                  </div>
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      name="ClienteEnterprise"
                      type="checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Enterprise
                    </label>
                  </div>

                  <Button
                    onClick={async () => {
                      let SiteOrAp =
                        nivelAveria == "Site" ? sitesElegido : apAveriado;

                      BotonCircuitosInvididuales.current.value = "invididual";

                      Cargador.current.className = "animacionCarga";

                      BotonCircuitosInvididuales.current.className =
                        "btn btn-danger mt-3 mb-3 ms-3";

                      BotonCircuitosInvididuales.current.innerText = "Cancelar";

                      await getCircuitos(
                        [
                          values.ClienteBusinessVIP,
                          values.ClienteWholesale,
                          values.ClienteBusiness,
                          values.ClienteEnterprise,
                        ],
                        SiteOrAp
                      );

                      Cargador.current.className = "d-none";
                    }}
                    className={
                      values.ClienteBusinessVIP ||
                      values.ClienteWholesale ||
                      values.ClienteBusiness ||
                      values.ClienteEnterprise
                        ? "animacionPanel mt-3 mb-3"
                        : "d-none"
                    }
                  >
                    Elegir Circuitos Invididualmente
                  </Button>

                  <Button
                    ref={BotonCircuitosInvididuales}
                    value="todos"
                    onClick={() => {
                      classCajaCircuitos.current.className = "d-none";

                      BotonCircuitosInvididuales.current.className = "d-none";

                      BotonCircuitosInvididuales.current.value = "todos";

                      Cargador.current.className = "d-none";
                    }}
                    className="d-none"
                  >
                    Cancelar
                  </Button>

                  <div>
                    <img
                      className="d-none"
                      ref={Cargador}
                      src={ImgRoute.CargarSimbol}
                    />
                  </div>

                  {/*CAJA PARA LA LISTA DE CIRCUITOS | NOTA='ESTO DEBERIA IR EN UN COMPONENTE APARTE'*/}
                  <div className="" ref={classCajaCircuitos}>
                    <ol className="mx-auto">
                      {listaCircuitos.map((element, index) => (
                        <li className="h5">
                          <div
                            className="p-2"
                            style={{
                              "border-left": `15px solid ${element.EtiquetaColor}`,
                            }}
                          >
                            {element.NombreCircuito} - {element.CircuitoId} -{" "}
                            <span>
                              ({element.Cliente} {element.Compañia})
                            </span>
                            <input
                              type="checkbox"
                              id={index}
                              className="ms-2 form-check-input"
                            />
                          </div>{" "}
                          <hr />
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
                {/*CAJA PARA EL MENSAJE A ENVIAR*/}
                <div className="mb-1">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label tns"
                  >
                    Mensaje a Enviar:
                  </label>
                </div>

                <div className="form-floating">
                  <Field
                    as="textarea"
                    className="form-control"
                    style={{ height: "180px" }}
                    name="textoEmailMultiples"
                    required
                  />

                  <label htmlFor="floatingTextarea2">
                    Este es el texto que enviará por Email:
                  </label>
                  <div className="form-text">
                    Añade el Nombre del Cliente con <b>#cliente</b> y el
                    circuito con <b>#servicio</b>
                  </div>
                </div>
                {/*CAJA PARA EL BOTON DE CREAR TICKETS*/}
                <div className="row mt-2">
                  <div className="col-12">
                    <ReCAPTCHA
                      sitekey="6LdAog0dAAAAAP3L8n8FGLWly9FA_lsnUntE5V6n"
                      ref={verificacionreCaptchaClientesMultiples}
                    />
                  </div>
                  <div className="col-3">
                    <button type="submit" className="btn btn-primary btnosnet">
                      Crear Tickets
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* PARA CLIENTES RESIDENCIALES */}
      <div ref={cajaFormularioClientesResidenciales} className="d-none">
        <FormularioClientesResidenciales sitesObjetos={sitesObjeto} />
      </div>
    </div>
  );
}
