import React, { useRef, useState } from "react";
import ip from "../Ip/IpPeticiones";
import axios from "axios";
import { toast } from "react-toastify";
import { InvalidToken } from "../helpers/InvalidToken";
import { useNavigate } from "react-router-dom";

export default function ActualizarLotes({ handleGetLotes, Lotes, setLotes }) {
  const navigate = useNavigate();

  //PARA LA CAJA DIV QUE CONTIENE LA DATA ACTUALIZAR LOTES
  const refUpdateLotes = useRef();
  //PARA EL TITULO QUE SE COLOCA EN EL FORMULARIO DE AVERIAS
  const tituloAveria = useRef();
  //PARA LA TABLA DE LOTES
  const tablaLotes = useRef();
  //PARA LA CAJA DIV QUE CONTIENE EL FORMULARIO DE ACTUALIZAR TICKETS
  const CajaTextoUpdateTickets = useRef();
  const idLoteElegidoUpdate = useRef();
  //PARA EL TEXTO DEL FORMULARIO DE ACTUALIZAR TICKETS
  const textoUpdateTickets = useRef();

  //ESTADO PARA EL RADIO BUTTON
  const [CorF, setCorF] = useState(1);
  //PARA LA INFO DE LOS LOTES EN ACTUALIZAR LOTES
  const [loteTickets, setLotesTickets] = useState([]);

  //PARA CAMBIAR LAS CLASES CUANDO SE LE DE CLICK AL BOTON ACTUALIZAR DE LA LISTA
  const actualizarBoletos = (e) => {
    //COLOCANDO EL TITULO
    tituloAveria.current.innerText = e.target.name;
    //VOLVIENDO INVISIBLE LA LISTA DE LOTES
    tablaLotes.current.className = "d-none";

    CajaTextoUpdateTickets.current.className = "form-control";

    setLotesTickets(e.target.value);
    idLoteElegidoUpdate.current = e.target.id;
  };
  //PARA CREAR LOS COMENTARIOS A LOS LOTES
  const createCommentLotes = async (e) => {
    try {
      //trayendo los boletos elegidos y creando un arreglo
      const tickets = loteTickets.split(",");

      //HACIENDO LA PETICION PARA CREAR LOS COMENTARIOS MULTIPLES

      const peticion = await axios.post(
        `${ip}/Boletos/CreateComentario`,
        {
          tickets,
          comentario: textoUpdateTickets.current.value,
          CorF,
          idLote: idLoteElegidoUpdate.current,
        },
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );
      //MANDANDO EL MENSAJE INDICANDO QUE TODO SALIO BIEN
      toast.success("Lotes Actualizados");
    } catch (error) {
      //POR SI ACASO HAY UN ERROR
      let errorValor =
        error.response.data.Problems[0] !== undefined
          ? error.response.data.Problems[0]
          : error.response.data.Problems;
      // if it sends me any error it will disappear the table update
      tablaLotes.current.className = "table table-striped";
      CajaTextoUpdateTickets.current.className = "d-none";
      //With this we get the current Lotes
      handleGetLotes(setLotes);
      // //MANDANDO EL MENSAJE CON EL ERROR
      toast.warning(errorValor);
      InvalidToken(error, navigate);
    }
  };

  return (
    <>
      {/*PANEL ACTUALIZAR LOTES */}
      <div
        className="tab-pane fade animacionPanel"
        id="profile"
        ref={refUpdateLotes}
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div className="mb-3 lotesTamano animacionPanel">
          <p className="h2 tnt mt-4 ">Actualizar Tickets</p>
          {/*CAJA RENDERIZADO CONDICIONAL */}
          <div>
            <table ref={tablaLotes} className="table table-striped">
              <thead>
                <tr>
                  <th className="ttab" scope="col">
                    Tipo de Avería
                  </th>
                  <th className="ttab" scope="col">
                    Cant. Tickets
                  </th>
                  <th className="ttab" scope="col">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  //FILAS DE LA TABLA ACTUALIZAR
                  Lotes.map((element) => {
                    if (element.mensajeAveria) {
                      return (
                        <tr key={element.ID}>
                          <td>{element.mensajeAveria}</td>
                          <td>{element.Boletos.length}</td>
                          <td key={element.ID}>
                            <button
                              type="button"
                              className="btn btn-primary btnclose"
                              name={element.mensajeAveria}
                              id={element.ID}
                              value={element.Boletos}
                              onClick={actualizarBoletos}
                            >
                              Actualizar
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })
                }
              </tbody>
            </table>

            {Lotes.length == 0 ? (
              <h2 className="text-center fw-bold text-danger">
                No Hay Tickets Creados
              </h2>
            ) : (
              ""
            )}

            {/*PARA EL FORMULARIO DE ACTUALIZAR LOTES*/}
            <div ref={CajaTextoUpdateTickets} className="d-none ">
              <h1 className="fw-bold text-center" ref={tituloAveria}>
                Averia
              </h1>
              <textarea
                style={{ height: "180px", width: "600px" }}
                ref={textoUpdateTickets}
              ></textarea>
              <br />
              {/*RADIO BUTTONS PARA ELEGIR EL TIPO DE MENSAJE A AGREGAR AL BOLETO */}

              <div className="row mt-2 mb-2">
                <div className="col-6">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input "
                      type="radio"
                      value={1}
                      onChange={(e) => setCorF(e.target.value)}
                      checked={CorF == 1}
                      name="flexRadioDefault"
                      required
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexRadioDefault1"
                    >
                      Comentario
                    </label>
                  </div>
                  <div className="form-check  form-check-inline">
                    <input
                      className="form-check-input "
                      type="radio"
                      value={0}
                      onChange={(e) => setCorF(e.target.value)}
                      checked={CorF == 0}
                      name="flexRadioDefault"
                      required
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexRadioDefault2"
                    >
                      Email
                    </label>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-primary "
                onClick={(e) => {
                  tablaLotes.current.className = "table table-striped";
                  CajaTextoUpdateTickets.current.className = "d-none";
                }}
              >
                Volver
              </button>
              <button
                className="ms-2 btn btn-success"
                onClick={(e) => {
                  createCommentLotes();
                }}
              >
                Actualizar Lote
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
