import jwt from "jsonwebtoken";

export const Token = (navigate, token, Usertoken) => {
  if (token && Usertoken) {
    const user = jwt.decode(token);
    let RegEx_UserToken = Usertoken.replace(/['"]+/g, "");
    //veriticating if user name and user token have the same name --Jossep11
    if (user?.data === RegEx_UserToken) {
      navigate("/home");
    } else {
      navigate("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
    }
  } else {
    navigate("/");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
  }
};
