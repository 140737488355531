import React from "react";
import { Navigate } from "react-router-dom";
import jwt from "jsonwebtoken";

export default function PublicRoute({ children }) {
  //Getting the token
  const token = sessionStorage.getItem("token");

  //checking if the token exist
  if (token) {
    const user = jwt.decode(token);
    //checking if the token is valid
    if (user) {
      return <Navigate to={"/home"} />;
    } else {
      return children;
    }
  } else {
    return children;
  }
}
