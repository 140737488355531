import { createContext, useState, useRef } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  //ESTADO VALOR
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || null
  );

  const login = (dataUser, token) => {
    setUser(dataUser);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("user", JSON.stringify(dataUser));
  };

  //OBJETO QUE SE VA A PASAR GLOBAL
  const contextValue = {
    user,
    login,
    logout() {
      setUser(null);
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
