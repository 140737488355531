import axios from "axios";
import ip from "../Ip/IpPeticiones";

export const usePeticiones = () => {
  const handleGetLotes = async (setLotes) => {
    try {
      //PETICION PARA LOS BOLETOS
      const AveriasBoleto2 = axios.get(`${ip}/Boletos/Lote`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      //PETICION PARA LOS DESVIOS
      const AveriasDesvio2 = axios.get(
        `${ip}/ResidentialManage/getAveriasData`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );
      //REALIZANDO LAS DOS PETICIONES DE MANERA PARALELA
      const dataP2 = await Promise.all([AveriasDesvio2, AveriasBoleto2]);
      //UNIENDO LOS DATOS DE LAS PETICIONES EN 1 SOLO ARREGLO
      let dataUnite2 = dataP2[0].data.concat(dataP2[1].data);
      //ACTUALIZANDO EL ESTADO
      setLotes(dataUnite2);
      console.log(dataUnite2);
    } catch (error) {
      setLotes([]);
      console.log(error.message);
    }
  };

  // Update in a future
  // const handlecreateCommentLotes = async (
  //   textoUpdateTickets,
  //   CorF,
  //   idLoteElegidoUpdate,
  //   tickets,
  //   tablaLotes,
  //   CajaTextoUpdateTickets,
  //   setLotes,
  //   toast,
  //   InvalidToken,
  //   navigate
  // ) => {
  //   console.log("test");
  //   try {
  //     console.log("works");
  //     const peticion = await axios.post(
  //       `${ip}/Boletos/CreateComentario`,
  //       {
  //         tickets,
  //         comentario: textoUpdateTickets.current.value,
  //         CorF,
  //         idLote: idLoteElegidoUpdate.current,
  //       },
  //       {
  //         headers: {
  //           token: sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     //POR SI ACASO HAY UN ERROR
  //     let errorValor =
  //       error.response.data.Problems[0] !== undefined
  //         ? error.response.data.Problems[0]
  //         : error.response.data.Problems;
  //     // if it sends me any error it will disappear the table update
  //     tablaLotes.current.className = "table table-striped";
  //     CajaTextoUpdateTickets.current.className = "d-none";
  //     handleGetLotes(setLotes);
  //     // //MANDANDO EL MENSAJE CON EL ERROR
  //     toast.warning(errorValor);
  //     InvalidToken(error, navigate);
  //   }
  // };

  return { handleGetLotes };
};
