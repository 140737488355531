//IMPORTACIONES
import { React, useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import MultiplesAfectados from "./ComponenteForm/MultiplesAfectados";
import UnicoAfectado from "./ComponenteForm/UnicoAfectado";
import "./App.css";
import GetInfoClientsSite from "./ComponenteForm/GetInfoClientsSite";
import ip from "./Ip/IpPeticiones";
import { useNavigate } from "react-router-dom";
import { Token } from "./helpers/Token";
import { InvalidToken } from "./helpers/InvalidToken";
import { usePeticiones } from "./hooks/usePeticiones";
import ImgRoute from "./helpers/ImgRoute";
import Paneles from "./Paneles";
import ActualizarLotes from "./ComponenteForm/ActualizarLotes";
import DeleteLotes from "./ComponenteForm/DeleteLotes";

export default function Formulario() {
  const navigate = useNavigate();
  const { handleGetLotes } = usePeticiones();

  //PARA LA INFO DE LOS LOTES EN LOTES
  const [Lotes, setLotes] = useState([]);
  //PARA EL CAMBIO DE PANELES
  const [changePanel, setChangePanel] = useState(1);

  //REFERENCIAS

  //PARA EL BUTTON DE OPCION DE MULTIPLES CLIENTES
  const refButtonSelectMultiplesClientes = useRef();
  //PARA EL BUTTON DE OPCION DE UN CLIENTE
  const refButtonSelectUnCliente = useRef();
  //PARA EL BUTTON DE OPCION DE REPORT
  const refButtonSelectReports = useRef();
  //PARA EL BUTTON DE OPCION DE LOTES
  const refButtonSelectLotes = useRef();
  //PARA EL BUTTON DE OPCION DE ACTUALIZAR LOTES
  const refButtonSelectUpdateLotes = useRef();

  let token = sessionStorage.getItem("token");
  let Usertoken = sessionStorage.getItem("user");

  //CICLO DE VIDA DE ACTUALIZACION
  useEffect(async () => {
    switch (changePanel) {
      //MULTIPLES CLIENTES
      case 1:
        //CAMBIANDO LA CLASE DEL BOTON DE OPCION MULTIPLES CLIENTES PARA QUE SE VEA MARCADO
        refButtonSelectMultiplesClientes.current.className =
          "nav-link ttab active";
        //CAMBIANDO LA CLASE DEL BOTON DE UN CLIENTE PARA QUE SEA UN LINK NORMAL
        refButtonSelectUnCliente.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE LOTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectLotes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE REPORTS PARA QUE SEA UN LINK NORMAL
        refButtonSelectReports.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE ACTUALIZAR LOTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectUpdateLotes.current.className = "nav-link ttab";

        break;
      //UN CLIENTE
      case 2:
        //CAMBIANDO LA CLASE DEL BOTON DE OPCION UN CLIENTE PARA QUE SE VEA MARCADO
        refButtonSelectUnCliente.current.className = "nav-link ttab active";
        //CAMBIANDO LA CLASE DEL BOTON DE MULTIPLES CLIENTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectMultiplesClientes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE LOTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectLotes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE REPORTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectReports.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE ACTUALIZAR PARA QUE SEA UN LINK NORMAL
        refButtonSelectUpdateLotes.current.className = "nav-link ttab";

        break;
      //REPORTES
      case 3:
        //CAMBIANDO LA CLASE DEL BOTON DE OPCION REPORTES PARA QUE SE VEA MARCADO
        refButtonSelectReports.current.className = "nav-link ttab active";
        //CAMBIANDO LA CLASE DEL BOTON DE UN MULTIPLES CLIENTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectMultiplesClientes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE UN CLIENTE PARA QUE SEA UN LINK NORMAL
        refButtonSelectUnCliente.current.className = "nav-link ttab";

        break;
      //LOTES
      case 4:
        //CAMBIANDO LA CLASE DEL BOTON DE OPCION LOTES PARA QUE SE VEA MARCADO
        refButtonSelectLotes.current.className = "nav-link ttab active";
        //CAMBIANDO LA CLASE DEL BOTON DE MULTIPLES CLIENTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectMultiplesClientes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE UN CLIENTE PARA QUE SEA UN LINK NORMAL
        refButtonSelectUnCliente.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE ACTUALIZAR PARA QUE SEA UN LINK NORMAL
        refButtonSelectUpdateLotes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE REPORTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectReports.current.className = "nav-link ttab ";

        break;
      //ACTUALIZAR LOTES
      case 5:
        //CAMBIANDO LA CLASE DEL BOTON DE OPCION ACTUALIZAR LOTES PARA QUE SE VEA MARCADO
        refButtonSelectUpdateLotes.current.className = "nav-link ttab active";
        //CAMBIANDO LA CLASE DEL BOTON DE MULTIPLES CLIENTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectMultiplesClientes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE UN CLIENTE PARA QUE SEA UN LINK NORMAL
        refButtonSelectUnCliente.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE LOTES PARA QUE SEA UN LINK NORMAL
        refButtonSelectLotes.current.className = "nav-link ttab";
        //CAMBIANDO LA CLASE DEL BOTON DE REPORT PARA QUE SEA UN LINK NORMAL
        refButtonSelectReports.current.className = "nav-link ttab";

        break;

      default:
        break;
    }
    // To validate the token
    Token(navigate, token, Usertoken);
  }, [token, Usertoken, changePanel]);

  //PARA PEDIR LOS LOTES DE TICKETS CREADOS
  const getLotes = async (updateOr = "NINGUNO") => {
    try {
      //PARA CUANDO SE LLAME LA FUNCION GETLOTES DESDE CERRARTICKETS
      if (updateOr == "LOTE") {
        //PETICION PARA LOS BOLETOS DE DESVIO
        const AveriasBoleto = await axios.get(`${ip}/Boletos/Lote`, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        });
        //OBTENIENDO LOS DESVIOS
        let desvios = Lotes.filter((element) => element.Nodo);
        //UNIENDO LOS BOLETOS Y LOS DESVIOS EN UN SOLO ARRAY Y ASIGNANDOLO AL ESTADO
        setLotes(desvios.concat(AveriasBoleto.data));

        return 0;
      }

      //PETICION PARA LOS BOLETOS
      const AveriasBoleto = axios.get(`${ip}/Boletos/Lote`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      //PETICION PARA LOS DESVIOS
      const AveriasDesvio = axios.get(
        `${ip}/ResidentialManage/getAveriasData`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );

      //REALIZANDO LAS DOS PETICIONES DE MANERA PARALELA
      const dataP = await Promise.all([AveriasDesvio, AveriasBoleto]);
      //UNIENDO LOS DATOS DE LAS PETICIONES EN 1 SOLO ARREGLO
      let dataUnite = dataP[0].data.concat(dataP[1].data);
      //ACTUALIZANDO EL ESTADO
      setLotes(dataUnite);
    } catch (error) {
      InvalidToken(error, navigate);
    }
  };

  toast.configure();

  return (
    <div className="bgosnet animacionPanel">
      <div className="container">
        <div className="row  FondoFila align-items-center gx-5 ">
          <div className="col-6 tn topito tamanoCajaPrincipal">
            {/* BARRA DE OPCIONES */}
            <ul className="nav nav-tabs " id="myTab" role="tablist">
              {/*OPCION DE MULTIPLES CLIENTES*/}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link ttab active"
                  id="home-tab"
                  onClick={(e) => {
                    setChangePanel(1);
                  }}
                  ref={refButtonSelectMultiplesClientes}
                  name="Múltiples Clientes"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Múltiples Clientes
                </button>
              </li>
              {/*OPCION DE UN CLIENTE*/}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link ttab "
                  onClick={(e) => {
                    setChangePanel(2);
                  }}
                  ref={refButtonSelectUnCliente}
                  name="Un Cliente"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Un Cliente
                </button>
              </li>
              {/*OPCION DE REPORT*/}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link ttab "
                  onClick={(e) => {
                    setChangePanel(3);
                  }}
                  ref={refButtonSelectReports}
                  name="Un Cliente"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Report
                </button>
              </li>
              {/*OPCION DE LOTES*/}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link ttab "
                  onClick={(e) => {
                    setChangePanel(4);
                    getLotes();
                  }}
                  ref={refButtonSelectLotes}
                  name="Lotes"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Lotes
                </button>
              </li>
              {/*OPCION DE ACTUALIZAR LOTES*/}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link ttab "
                  onClick={(e) => {
                    setChangePanel(5);
                    getLotes();
                  }}
                  ref={refButtonSelectUpdateLotes}
                  name="Actualizar Lotes"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Actualizar Lotes
                </button>
              </li>
            </ul>
            {/* PANELES DE OPCIONES */}
            <div className="tab-content " id="myTabContent">
              {/* PANEL MULTIPLES CLIENTES */}
              <Paneles
                element={<MultiplesAfectados />}
                show={changePanel}
                comparation={1}
              />
              {/* PANEL UN CLIENTE */}
              <Paneles
                element={<UnicoAfectado />}
                show={changePanel}
                comparation={2}
              />
              {/* PANEL REPORTS */}
              <Paneles
                element={<GetInfoClientsSite />}
                show={changePanel}
                comparation={3}
              />
              {/* PANEL LOTES */}
              <Paneles
                element={
                  <DeleteLotes
                    Lotes={Lotes}
                    getLotes={getLotes}
                    setLotes={setLotes}
                  />
                }
                show={changePanel}
                comparation={4}
              />
              {/* PANEL ACTUALIZAR LOTES */}
              <Paneles
                element={
                  <ActualizarLotes
                    handleGetLotes={handleGetLotes}
                    Lotes={Lotes}
                    setLotes={setLotes}
                  />
                }
                show={changePanel}
                comparation={5}
              />
            </div>
          </div>
          <div className="col-6 topito ">
            <img src={ImgRoute.desconnect} className="mx-auto " />
          </div>
        </div>
      </div>
    </div>
  );
}
