import axios from "axios";
import React, { useState } from "react";

export const useData = (ip, toast, Usuario, Password) => {
  const [dataVerifa, setData] = useState("");
  const handleConsult = async (e) => {
    try {
      const respuesta = await axios.post(`${ip}/Session/validate`, {
        user: Usuario,
        password: Password,
      });
      setData(respuesta.data);
    } catch (error) {
      console.log(error);
      error.response.data.Problems !== undefined
        ? toast.warning(error.response.data.Problems[0])
        : toast.warning(error.response.data);
    }
  };

  return { dataVerifa, handleConsult };
};
